.jambo-container {
 width: 50%;
 display: flex;
 justify-content: space-between;
 margin: 0 auto;
}
.jambo-left {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    
}
.jambo-title-container {
    width: 85%;
    text-indent: 100px;
    padding-inline-start: 10px;
 
}
.jambo-descraption {
    width: 80%;
    color: #55595c;
    padding-top: 20px;
   padding-inline-start: 10px;
   padding-bottom: 20px;
}
.jambo-right {
width: 50%;
}
.jambo-reverse {
    flex-direction: row-reverse;
}
.jambo-left-direction {
    flex-direction: column-reverse;
}

@media only screen and (max-device-width: 700px) {
.jambo-container {
 padding: 2px;

}
.jambo-left {  
width: 100%;
}
.jambo-title-container {
    width: 100%;
}
.jambo-descraption{
    width: 100%;
}
.jambo-right {
width: 100%;
}

}
@media only screen and (max-device-width: 700px) {
    .services {
      width: 100%;
      height: 100%;
    }
  
    .servicesText {
      width: 95%;
      margin: 2px auto;
      margin-top: 60px;
    }
  
    .header {
      position: absolute
    }
  
    .navbar {
      justify-content: space-between;
    }
    .mediaImageOrAnimation {
      padding-left: 0;
      margin: 0;
    }
    .hero-left {
      padding: 0;
  
    }
    .hero {
      height: 100vh;
    }
    .hero-left{
      padding: 20px;
    }
   
    .jambo-container {
      margin-top: 60px;
    }
    .services__box {
      height: 250px;
  
    }
    .short-text_Container {
      height: 280px;
    }
    .about-log-text_log_Container {
      height: 500px;
    }
  }
/* 
h1 {
  font-family: "Nunito", sans-serif;
  margin: 0;
  font-weight: lighter;
  color: white;
  font-size: 3rem;
} */

p {
  font-weight: lighter;
}

.buttonHeroImage {
  border: none;
  height: 50px;
  width: 100%;
  max-width: 180px;
  border-radius: 25px;
  line-height: 50px;
  background: #01a3a4;
  color: white;
  letter-spacing: 1px;
  font-weight: lighter;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.buttonHeroImage:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.hero {
  position: relative;
  height: 80%;
  width: 100%;

  box-sizing: border-box;
}

.background, .background-right, .background-left {
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
}

.background-left {
  z-index: -1;
  background: red;
  background: #16a085;
}

.background-right {
  z-index: -1;
  background: #16ab85;
  -webkit-clip-path: polygon(0 0, 0% 100%, 90% 100%);
          clip-path: polygon(0 0, 0% 100%, 90% 100%);
}

.hero-content {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  position: relative;
}
.hero-content p {
  margin: 3rem 0;
  max-width: 80%;
}

.hero-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  min-width: 50%;
  max-width: 400px;
  padding: 50px;
}
.hero-left h1 {
  color: #666;
  font-family: "Nunito", sans-serif;
}

.hero-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: relative;
  width: 50%;
  height: 100%;
  text-align: center;
}

.hero-image {
  position: relative;
  z-index: 99;
  width: auto;
  height: 100%;
  max-height: 500px;
}
.mediaDirection {
  flex-direction: row-reverse;
}
.mediaImageOrAnimation {
  max-width: 450px;
  animation:slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; 
  margin: 50px;
  padding-left: 20px;
  
}
.hero-mobile-View{
  position: absolute;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  z-index: -1;

}

.hero-mobile-image {
  height: 100vh;
  width: 100%;
  margin: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-animation {
  padding:20px;
}


@-webkit-keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
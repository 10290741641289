.contact-form {
    padding: 10px;
    width: 100%;
    }
    .contact-form-Wrapper {
        width: 90%;
        margin: 50px;
        display: flex;
    }
    .contact-form-left {
        width: 40%;
        height: 100vh;
        padding: 2px;
        background-color: antiquewhite;
    }
    .contact-form-right {
        display: flex;
        width: 60%;
        justify-self: center;
        
    }
    .contact-form-left img {
        width: 100%;
        height: 100vh;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .contact-form-left .overflow {
        position: absolute;
        width: 20%;
        height: 100vh;
        background-color: black;
        opacity: 0.5;
        transition: width 5s;
    } 
    .contact-form-left .overflow:hover {
       width: 36%;
    }
    @media only screen and (max-device-width: 700px) {
        .contact-form-left{
            display: none;
        }
        .contact-form-right {
            width: 100%;
        }
    }
/*Importing Montserrat Font*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

/*Global Styles*/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}



/*Services Styles*/
.services {
  position: relative;
  width: 80%;
  height: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin: auto;
  
}

.services__box {
  position: relative;
  width: 450px;
  height: 480px;
  overflow: hidden;
}

.services__box:hover .services__content {
  transform: scale(1);
  transition-delay: 0.25s;
}

.services__box:hover .services__icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 30px;
  left: calc(50% - 40px);
  transition-delay: 0s;
}

.services__box:hover .services__icon img {
  font-size: 2rem;
  transition-delay: 0s;
  height: 100%;
}

.services__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--i);
  transition: 0.5s;
  z-index: 2;
  transition-delay: 0.25s;
}

.services__icon img {
  font-size: 4rem;
  color: #666;
  font-weight: bold;
  transition: 0.5s;
  transition-delay: 0s;
  height: 100%;
  border-radius: 30px;

}

.services__content {
  position: relative;
  color: #666;
  padding: 20px;
  text-align: center;
  margin-top: 100px;
  z-index: 1;
  transform: scale(0);
  transition: 0.5s;
  transition-delay: 0s;
}

.services__title {
  margin: 10px 0 5px 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.services__description {
  font-size: 16px;
  padding-top: 10px;
}

.servicesText {
  width: 84%;
  padding-top: 93px;
  margin: 20px auto;
  padding-bottom: 30px;
}

.servicesText h1,
h2 {
  color: #226d89;
  font-weight: bold;
}

.sercivestext-Des {
  justify-items: left;
  color: gray;
  max-width: 80%;
  font-size: 16px;
  padding-top: 10px;
}

.servicesContent-Wrapper {
  max-width: 90%;
  height: 400px;
  margin: auto;
  background-color: #666;
}




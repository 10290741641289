
.steps {
  position: relative;
  width: 100%;
  margin: auto;
}
.steps:before {
  top: 180px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 2px;
  background-color: #01a3a4;
  left: 50%;
  margin-left: -1px;
}

.timeline {
  margin: 0px auto;
  overflow: hidden;
  position: relative;
  padding: 0px;
  list-style-type: none;
}
.timeline .timeline-box {
  position: relative;
  float: left;
  clear: left;
  width: 45%;
  margin: 1em 2.5%;
  list-style-type: none;
  display: block;
  padding: 25px;
  background: #f8f8f8;
  z-index: 15;
}

.timeline .timeline-box .timeline-title {
  color: #666;
  font-weight: 700;
  font-size: 1em;
  float: left;
  padding-left: 25px;
  padding-top: 8px;
}
.timeline .timeline-box .timeline-details {
  clear: both;
  padding-top: 5px;
}
.timeline .timeline-box span {
  position: absolute;
  top: -15px;
  right: 24px;
  font-size: 8em;
  font-weight: 700;
  color: #01a3a4;
  opacity: 0.3;
  z-index: -1;
}
@media all and (max-width: 600px) {
  .timeline .timeline-box {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .steps:before {
    top: 280px;}
}
.timeline .timeline-box:hover:after {
  background: #01a3a4;
}
@media all and (min-width: 650px) {
  .timeline .timeline-box:after {
    display: block;
    content: ' ';
    height: 9px;
    width: 9px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: -6.55%;
    top: 1.5em;
    border: 2px solid #01a3a4;
  }
}
.timeline .timeline-box:first-child {
  margin-bottom: 2.5em;
}
.timeline .timeline-box:nth-of-type(1n):before {
  position: absolute;
  top: 15px;
  right: -10px;
  display: inline-block;
  border-top: 10px solid transparent;
  border-left: 10px solid #f8f8f8;
  border-right: 0 solid #f8f8f8;
  border-bottom: 10px solid transparent;
  content: " ";
}
.timeline .timeline-box:nth-of-type(2n) {
  float: right;
  clear: right;
}
.timeline .timeline-box:nth-of-type(2n):before {
  right: auto;
  left: -10px;
  position: absolute;
  top: 15px;
  display: inline-block;
  border-top: 10px solid transparent;
  border-right: 10px solid #f8f8f8;
  border-left: 0 solid #f8f8f8;
  border-bottom: 10px solid transparent;
  content: " ";
}
@media all and (min-width: 600px) {
  .timeline .timeline-box:nth-of-type(2n) {
    margin-top: 3em;
  }
  .steps-area {
    padding:  0;
  }
}
.timeline .timeline-box:nth-child(2n):after {
  left: -6.5%;
  right: auto;
}

.timeline-box:hover, .timeline-box:focus {
  transform: translate(0, -5px);
  -webkit-transform: translate(0, -5px);
  -ms-transform: translate(0, -5px);
}

.timeline-box:hover, .timeline-box:focus {
  -webkit-box-shadow: 0px 3px 0px 0px #01a3a4;
  -moz-box-shadow: 0px 3px 0px 0px #01a3a4;
  box-shadow: 0px 3px 0px 0px #01a3a4;
}

.timeline-box {
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
}


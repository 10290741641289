.footer-container {
    display: flex;
    width: 100%;
    height: 90px;
    background-color: black;
    align-self: flex-end;
    margin-top: 120px;
}
.footer-container>.footer-copyright {
    width: 100%;
    color:#fff;
     font-size: 12px;
   align-self: center;
   text-align: center;

}
:root {
  --main:	#FF00FF;
  --link:#097B92
}
nav a {
    margin-left: 30px;
    align-self: center;
    cursor: pointer;
    color: gray !important;
    text-decoration: none;
}
nav a:hover{
    color: var( --link) !important;
}

.logo img {
  margin-top: 10px;
  width: 212px;
  height: 40px;
}
.active {
  color: var( --link) !important;
}
  .navbar {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: sans-serif;
    padding: 10px 50px;
    height: 100px;
    z-index: 2;
  }
  .navbar .logo {
    width: 80%;
  }
  .push-left {
    margin-left: auto;
  }
  
  /* Menu */
  .hamburger {
    background: transparent;
    border: none;
    cursor: pointer;
    display: none;
    outline: none;
    height: 30px;
    position: relative;
    width: 30px;
    z-index: 1000;
  }
  @media screen and (max-width: 768px) {
    .hamburger {
      display: inline-block;
    }
  }
  .hamburger-line {
    background: var( --link);
    height: 3px;
    position: absolute;
    left: 0;
    transition: all 0.2s ease-out;
    width: 100%;
    z-index: 12212;
  }
  .hamburger:hover .hamburger-line {
    background:var( --link);
  }
  .hamburger-line-top {
    top: 3px;
  }
  .menu-active .hamburger-line-top {
    top: 50%;
    transform: rotate(45deg) translatey(-50%);
  }
  .hamburger-line-middle {
    top: 50%;
    transform: translatey(-50%);
  }
  .menu-active .hamburger-line-middle {
    left: 50%;
    opacity: 0;
    width: 0;
  }
  .hamburger-line-bottom {
    bottom: 3px;
  }
  .menu-active .hamburger-line-bottom {
    bottom: 50%;
    transform: rotate(-45deg) translatey(50%);
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    /* transition: all 0.25s ease-in; */
  }
  
 
  @media screen and (max-width: 768px) {
    .nav-menu {
        display: none;
    }
 
    .nav-menu a {
      color: #fff !important;
    }
    

    .new__menu {
        background: rgba(0, 0, 0, 0.953);
        width: 100%;
        height: 400px;
        position: fixed;
        left: 0;
        top: 103px;
  
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
       z-index: 12;
       
      }
  
  }
  .new__menu a {
    color: #fff;
    display: block;
    line-height: 30px;
    margin: 0px 10px;
    text-decoration: none;
    text-transform: uppercase;
  }
  .nav-menu .menu-item a:hover {
    color: brown;
    text-decoration: none;
  }
  @media screen and (max-width: 768px) {
    .menu-item a {
      font-size: 20px;
      margin: 8px;
    }
  }
  